<template>
  <v-card v-if="showField()" class="field-card pa-6 pa-md-10 mb-12 rounded-xl" :class="readonly ? 'readonly-card' : ''" elevation="0">
    <h2>{{ title }}</h2>
    <p class="mb-4">{{ description }}</p>
    <slot name="content" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyFirstCredit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState("borrower", ["creditHistory"])
  },
  methods: {
    showField() {
      if (this.creditHistory.length > 0 && this.onlyFirstCredit) return false;
      else return true;
    }
  }
};
</script>

<style scoped>
.field-card {
  border: 2px solid #00f9ad;
}
.readonly-card {
  background-color: #e0e0e0;
}
</style>
